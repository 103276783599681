<template>
  <div class="footer">
    <custom-button
      class="main-button"
      v-if="mainButton"
      :button="mainButton"
    ></custom-button>
  </div>
</template>

<script>
import CustomButton from "./CustomButton.vue";

export default {
  components: { CustomButton },
  name: "MainFooter",
  props: {
    mainButton: Object,
  },
};
</script>

<style lang="less" scoped>
.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: var(--bkg-menu-color);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  padding-left: calc(env(safe-area-inset-left) + 10px);
  padding-right: calc(env(safe-area-inset-right) + 10px);
  padding-bottom: env(safe-area-inset-bottom);
  box-sizing: border-box;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 4;
  min-height: calc(2.8em + 2 * 15px + 1px);
  min-height: calc(2.8em + 2 * 15px + 1px + env(safe-area-inset-bottom));

  .main-button {
    flex: 1;
    margin: 0;
    padding: 0 10px;
    max-width: 400px;
  }
}
</style>
