<template>
  <div class="page page-with-submenu" :class="modeClass">
    <loader :visible="uploading || loading" :number="uploadingPercentage" />
    <username-alert ref="usernameAlert" :callback="usernameAlertCallback" />
    <main-menu
      :title="viewTitle"
      :info="info"
      :infoDescription="infoDescription"
      :backButton="backButton"
      :actionButtons="actionButtons"
      :switchOptions="switchOptions"
    ></main-menu>
    <sub-menu>
      <span
        v-if="mode == 'download'"
        :data-text="$t('pictures_by')"
        class="text"
        >{{ $t("pictures_by") }}</span
      >
      <div class="button-container" v-if="mode == 'download'">
        <custom-select
          :options="selectOptions"
          v-model="selectedUser"
          :selectedValue="selectedUser"
        ></custom-select>
      </div>
      <div class="button-container" v-if="mode == 'download'">
        <custom-button :button="sortButton"></custom-button>
      </div>
      <div class="button-container">
        <custom-button :button="shareButton"></custom-button>
      </div>
    </sub-menu>
    <div class="list">
      <ul ref="list">
        <picture-list-element
          v-for="image in filteredImages"
          :key="'image' + image.id"
          :image="image"
          :selected="isSelected(image)"
          :clickCallback="onSelectClicked"
          :deleteClickCallback="onDeleteClicked"
        ></picture-list-element>
        <loading-list-element
          key="loadingListElement"
          v-if="numberOfImagesToProcess > 0 && firstLoadingFinished"
          :text="xImagesToProcessText"
        ></loading-list-element>
      </ul>
    </div>
    <main-footer :mainButton="footerMainButton"></main-footer>
  </div>
</template>

<script>
import UsernameAlert from "../components/UsernameAlert.vue";
import config from "../config";
import Dropzone from "../assets/js/dropzone.min.js";
import MainMenu from "../components/MainMenu.vue";
import SubMenu from "../components/SubMenu.vue";
import Loader from "../components/Loader.vue";
import api from "../api";
import LoadingListElement from "../components/LoadingListElement.vue";
import PictureListElement from "../components/PictureListElement.vue";
import MainFooter from "../components/MainFooter.vue";
import CustomSelect from "../components/CustomSelect.vue";
import CustomButton from "../components/CustomButton.vue";
import { AVAILABLE_LANGUAGES } from "../i18n/languages";

Dropzone.autoDiscover = false;

export default {
  name: "Folder",
  components: {
    MainMenu,
    SubMenu,
    Loader,
    LoadingListElement,
    PictureListElement,
    MainFooter,
    UsernameAlert,
    CustomSelect,
    CustomButton,
  },
  metaInfo() {
    return {
      title: this.viewTitle,
      meta: [
        {
          property: "og:url",
          content: this.url,
        },
        { property: "og:type", content: "website" },
        {
          property: "og:title",
          content: `${this.viewTitle} - Uploader`,
        },
        {
          property: "og:description",
          content: this.$i18n.t("meta_description"),
        },
        {
          property: "og:image",
          content: this.images.length > 0 ? this.images[0].url : undefined,
        },
      ],
      link: [
        ...AVAILABLE_LANGUAGES.map((language) => ({
          rel: "alternate",
          hreflang: language,
          href: `${this.url}&lang=${language}`,
        })),
        {
          rel: "alternate",
          hreflang: "x-default",
          href: this.url,
        },
        {
          rel: "canonical",
          href: this.url,
        },
      ],
    };
  },
  data() {
    return {
      folder: null,
      viewTitle: "",
      selectedUser: "",
      numberOfImages: null,
      numberOfImagesToProcess: null,
      numberOfImagesToUpload: 0,
      numberOfImagesUploaded: 0,
      username: null,
      images: [],
      selectedImages: [],
      lastUnselectedImage: null,
      dropZone: null,
      imageOrder: "asc",
      uploading: false,
      loading: false,
      uploadingPercentage: null,
      interval: null,
      firstLoadingFinished: false,
    };
  },
  computed: {
    mode() {
      return this.$root.mode;
    },
    url() {
      return `${config.host}${this.$route.path}?token=${this.$route.query.token}`;
    },
    folderId() {
      return this.$route.params.id;
    },
    usernames() {
      return this.folder ? this.folder.usernames : [];
    },
    selectOptions() {
      const options = [{ value: "", text: this.$i18n.t("everybody") }];
      this.usernames.map((username) => {
        options.push({
          value: username,
          text: username,
        });
      });
      return options;
    },
    filteredImages() {
      return this.filterImages(this.images);
    },
    filteredSelectedImages() {
      return this.filterImages(this.selectedImages);
    },
    lastSelectedImage() {
      const lastIndex = this.selectedImages.length - 1;
      return this.selectedImages[lastIndex];
    },
    footerMainButton() {
      // uploader mode
      if (this.mode === "upload") {
        if (this.numberOfImagesToUpload > 0) {
          return {
            icon: "icon-upload",
            text: this.$i18n.tc("upload_x_photos", this.numberOfImagesToUpload),
            clickCallback: this.displayUsernameAlert,
          };
        }

        return {
          icon: "icon-plus",
          text: this.$i18n.t("add_photos"),
          clickCallback: this.onDZClicked,
        };
      }
      // download mode
      let buttonText = this.$i18n.t("download_all_photos");
      const selectNb = this.filteredSelectedImages.length;
      if (selectNb > 0 && selectNb != this.images.length) {
        buttonText = this.$i18n.tc("download_x_photos", selectNb);
      }
      if (this.images.length === 0) {
        return {
          icon: "icon-download",
          disabled: true,
          text: buttonText,
        };
      }
      return {
        icon: "icon-download",
        text: buttonText,
        clickCallback: this.onDownloadClicked,
      };
    },
    actionButtons() {
      const buttons = [];
      if (this.filteredSelectedImages.length > 0 && this.mode == "download") {
        buttons.push({
          customClass: "small-button margin-left-reduced",
          icon: "icon-cross",
          title: this.$i18n.t("cancel"),
          clickCallback: this.onCancelSelectionClicked,
        });
      }
      return buttons;
    },
    backButton() {
      if (!this.$root.jwtToken) {
        return undefined;
      }
      return {
        icon: "icon-arrow-left2",
        clickCallback: this.onBackClicked,
      };
    },
    switchOptions() {
      return {
        textOff: this.$i18n.t("upload"),
        iconOff: "icon-up",
        textOn: this.$i18n.t("download"),
        iconOn: "icon-down",
        state: this.mode != "upload",
        clickCallback: this.onSwitchClicked,
      };
    },
    shareButton() {
      return {
        icon: "icon-share",
        title: this.$i18n.t("share_folder"),
        clickCallback: this.copyLinkToClipboard,
      };
    },
    sortButton() {
      return {
        icon: "icon-sort",
        customClass: `sort-button-${this.imageOrder}`,
        title: this.$i18n.t("sort_images"),
        clickCallback: () => {
          this.imageOrder = this.imageOrder == "asc" ? "desc" : "asc";
        },
      };
    },
    totalNumberOfImages() {
      return (
        this.numberOfImages +
        this.numberOfImagesToProcess +
        this.numberOfImagesToUpload
      );
    },
    info() {
      return this.filteredSelectedImages.length > 0 && this.mode == "download"
        ? this.filteredSelectedImages.length + " / " + this.totalNumberOfImages
        : `${this.totalNumberOfImages}`;
    },
    infoDescription() {
      const number =
        this.filteredSelectedImages.length > 0 && this.mode == "download"
          ? this.filteredSelectedImages.length
          : this.totalNumberOfImages;
      return this.$i18n.tc("photos", number);
    },
    modeClass() {
      return ["page-" + this.mode];
    },
    xImagesToProcessText() {
      const number = this.numberOfImagesToProcess;
      return this.$i18n.tc("x_photos_in_process", number);
    },
  },
  watch: {
    numberOfImagesToProcess: function (val) {
      const component = this;
      if (this.interval) {
        clearInterval(this.interval);
      }
      if (val > 0) {
        this.interval = setInterval(async function () {
          try {
            await component.loadFolder();
            await component.loadImages();
          } catch (error) {
            component.$root.error = error.response;
          }
        }, 5000);
      }
    },
  },
  methods: {
    sortImages(order = "asc") {
      return (imageA, imageB) => {
        if (imageA.date < imageB.date) {
          return order == "asc" ? -1 : 1;
        }
        if (imageA.date > imageB.date) {
          return order == "asc" ? 1 : -1;
        }
        return 0;
      };
    },
    filterImages(images) {
      if (this.mode === "upload") {
        return images.sort(this.sortImages("asc"));
      }
      if (this.selectedUser === "") {
        return images.sort(this.sortImages(this.imageOrder));
      }
      return images
        .filter((image) => image.username === this.selectedUser)
        .sort(this.sortImages(this.imageOrder));
    },
    scrollToBottom() {
      window.scrollTo(0, document.body.scrollHeight);
    },
    attacheDropZone() {
      if (this.dropZone) {
        return;
      }
      this.dropZone = new Dropzone(document.body, {
        url: `${config.apiHost}/folder/${this.folderId}/upload?token=${this.$route.query.token}`, // Set the url
        thumbnailWidth: 200,
        thumbnailHeight: 200,
        parallelUploads: 1,
        timeout: 180000,
        previewTemplate: `<li><div class="list-content"><a data-dz-link="1" href="#"></a><img data-dz-thumbnail /><span class="icon-cross"></span><span class="size" data-dz-size ></span></div></li>`,
        autoQueue: false, // Make sure the files aren't queued until manually added
        previewsContainer: ".list ul", // Define the container to display the previews
        clickable: true, // Define the element that should be used as click trigger to select files.
        acceptedFiles: "image/jpeg,image/jpg,image/png",
        renameFile: this.onDZRenameFile,
        maxFilesize: 50,
        maxThumbnailFilesize: 50,
      });
      this.dropZone.on("addedfile", this.onDZUpdateFile(true));
      this.dropZone.on("removedfile", this.onDZUpdateFile(false));
      this.dropZone.on("error", this.onDZError);
      this.dropZone.on("success", this.onDZOnFileSuccess);
      this.dropZone.on("uploadprogress", this.onDZUploadProgress);
      this.dropZone.on("queuecomplete", this.onDZQueueComplete);
      this.dropZone.on("sending", this.onDZSending);
      // add custom events
      this.$el.addEventListener("click", (e) => {
        const element = e.target;
        if (element.getAttribute("data-dz-link") === "1") {
          e.preventDefault();
          this.onDZElementClicked(element);
        }
      });
    },
    onDZSending(file, xhr, formData) {
      if (this.username) {
        formData.append("username", this.username);
      }
    },
    onDZUpdateFile(scrollToBottom = false) {
      return () => {
        if (scrollToBottom) {
          this.scrollToBottom();
        }
        this.setNumerOfFilesToUpload();
      };
    },
    onDZError(file, error) {
      console.error("error", file, error);
      this.setNumerOfFilesToUpload();
      if (this.numberOfImagesToUpload === 0) {
        this.uploading = false;
        this.uploadingPercentage = null;
      }
    },
    onDZOnFileSuccess() {
      this.uploading = true;
      this.numberOfImagesUploaded += 1;
      if (this.numberOfImagesToUpload === 0) {
        this.uploadingPercentage = 100;
        return;
      }
      this.uploadingPercentage = Math.round(
        (this.numberOfImagesUploaded / this.numberOfImagesToUpload) * 100
      );
    },
    onDZQueueComplete() {
      this.dropZone.removeAllFiles();
      this.setNumerOfFilesToUpload();
      this.uploading = false;
      this.uploadingPercentage = null;
      this.load();
    },
    onDZRenameFile(file) {
      const name =
        Math.abs(file.lastModified) +
        "-" +
        Buffer.from(file.name).toString("base64");
      return name;
    },
    onDZUploadProgress() {
      var percent = this.uploadingPercentage;
      if (this.numberOfImagesToUpload > 0) {
        percent += 10 / this.numberOfImagesToUpload;
      }

      var nextPercent =
        ((this.numberOfImagesUploaded + 1) / this.numberOfImagesToUpload) * 100;

      if (percent > nextPercent) {
        return;
      }
      this.uploadingPercentage = Math.round(percent);
    },
    onDZClicked() {
      this.dropZone.hiddenFileInput.click();
    },
    onDZElementClicked(element) {
      if (this.loading) {
        return;
      }
      const list = this.$refs.list;
      const dzFiles = this.dropZone.files;
      if (!dzFiles) {
        return;
      }
      if (dzFiles.length === 0) {
        return;
      }
      const index =
        dzFiles.length -
        (list.childNodes.length -
          Array.from(list.childNodes).indexOf(element.parentNode.parentNode));
      this.dropZone.removeFile(dzFiles[index]);
    },
    displayUsernameAlert() {
      this.$refs.usernameAlert.visible = true;
    },
    usernameAlertCallback(username) {
      this.username = username;
      this.onUploadClicked();
    },
    onUploadClicked() {
      this.numberOfImagesUploaded = 0;
      this.uploading = true;
      this.uploadingPercentage = 0;
      this.dropZone.enqueueFiles(
        this.dropZone.getFilesWithStatus(Dropzone.ADDED)
      );
    },
    onDownloadClicked() {
      const downloadLink = `${config.apiHost}/folder/${this.folderId}/download?token=${this.$route.query.token}`;
      if (this.filteredSelectedImages.length === 0) {
        window.location = downloadLink;
        return;
      }
      const ids = this.filteredSelectedImages.map((i) => i.id).join(",");
      window.location = `${downloadLink}&images=${ids}`;
    },
    onBackClicked() {
      this.$router.push({ name: "home" });
    },
    onSwitchClicked(e, state) {
      this.$root.mode = state ? "download" : "upload";
    },
    onSelectClicked(e, image, withShift) {
      if (withShift) {
        this.onSelectClickedWithShift(image);
        return;
      }
      if (!this.isSelected(image)) {
        this.selectedImages.push(image);
      } else {
        this.lastUnselectedImage = image;
        this.selectedImages = this.selectedImages.filter(
          (oneImage) => oneImage.id != image.id
        );
      }
    },
    onDeleteClicked(e, image) {
      this.deleteImage(image);
    },
    onSelectClickedWithShift(image) {
      if (!this.isSelected(image)) {
        if (this.lastSelectedImage) {
          this.selectImagesBetweenIndexes(
            this.lastSelectedImage.id,
            image.id,
            true
          );
        }
      } else {
        if (this.lastUnselectedImage) {
          this.selectImagesBetweenIndexes(
            this.lastUnselectedImage.id,
            image.id,
            false
          );
        }
      }
    },
    onCancelSelectionClicked() {
      this.selectedImages = [];
    },
    isSelected(image) {
      return this.selectedImages.find((i) => i.id == image.id) != undefined;
    },
    selectImagesBetweenIndexes(index1, index2, select) {
      var firstIndex = Math.min(index1, index2);
      var lastIndex = Math.max(index1, index2);
      for (let index = firstIndex; index < lastIndex + 1; index++) {
        const image = this.images[index];
        if (select) {
          if (!this.isSelected(image)) {
            this.selectedImages.push(image);
          }
        } else {
          this.selectedImages = this.selectedImages.filter(
            (oneImage) => oneImage.id != image.id
          );
        }
      }
    },
    setNumerOfFilesToUpload() {
      this.numberOfImagesToUpload = this.dropZone.getFilesWithStatus(
        Dropzone.ADDED
      ).length;
    },
    async loadImages() {
      if (this.numberOfImages === this.images.length) {
        return;
      }
      const response = await api().get(`/folder/${this.folderId}/image`, {
        params: {
          token: this.$route.query.token,
        },
      });
      this.images = response.data.images;
      this.numberOfImages = this.images.length;
    },
    async loadFolder() {
      const response = await api().get(`/folder/${this.folderId}`, {
        params: {
          token: this.$route.query.token,
        },
      });
      this.folder = response.data.folder;
      this.viewTitle = this.folder.name;
      this.numberOfImagesToProcess = this.folder.numberOfImagesToProcess;
      this.numberOfImages = this.folder.numberOfImages;
    },
    async load() {
      this.loading = true;
      try {
        await this.loadFolder();
        await this.loadImages();
        this.firstLoadingFinished = true;
      } catch (error) {
        this.$root.error = error.response;
      }
      this.loading = false;
    },
    async deleteImage(image) {
      this.loading = true;
      try {
        await api(this.$root.jwtToken).delete(
          `/folder/${this.folderId}/image?images=${image.id}`,
          {
            params: {
              token: this.$route.query.token,
            },
          }
        );
        this.images = this.images.filter((i) => i.id != image.id);
        await this.loadImages();
      } catch (error) {
        this.$root.error = error.response;
      }
      this.loading = false;
    },
    async copyLinkToClipboard() {
      if (!navigator.clipboard) {
        alert(this.$i18n.t("impossible_to_copy_url_to_clipboard"));
        return;
      }
      try {
        await navigator.clipboard.writeText(
          `${this.url}&lang=${this.$i18n.locale}&mode=${this.mode}`
        );
        alert(this.$i18n.t("url_copied_to_clipboard"));
      } catch (error) {
        console.error(error);
        alert(this.$i18n.t("error_when_copy_url_to_clipboard"));
      }
    },
  },
  mounted() {
    this.$root.mode = this.$route.query.mode === "download" ? "download" : "upload";
    this.load();
    this.attacheDropZone();
  },
  beforeDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
    this.dropZone && this.dropZone.destroy();
    const dropZoneInput = this.$el.querySelector(".dz-hidden-input");
    dropZoneInput && dropZoneInput.remove();
  },
};
</script>

<style lang="less">
@import "../styles/list.less";
@import "../styles/form.less";

.sort-button-desc {
  .icon-sort {
    position: relative;
    transform: rotate(180deg);
  }
}
</style>
