<template>
  <li>
    <span class="list-content list-button">
      <span class="icon-spinner3"></span>
      <span class="text">{{ text }}</span>
    </span>
  </li>
</template>

<script>
export default {
  name: "LoadingListElement",
  props: {
    text: String,
  },
};
</script>

<style lang="less" scoped>
.list-button {
  color: var(--text-color);
  cursor: initial;
}

.icon-spinner3 {
  transform-origin: 50% 50%;
  transform: rotate(0deg);
  display: block;
  animation: loading 1s infinite;
  -webkit-animation: loading 1s infinite steps(12);
}

@keyframes loading {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
</style>
