<template>
  <li
    @click.exact="onClick"
    @click.shift.exact="onClickWithShift"
    :class="{
      selected: selected && !uploadMode,
      topGradient: (!uploadMode && image.username) || isAdmin,
    }"
  >
    <div class="list-content">
      <img :src="image.url" />
      <span
        @click.exact="onDeleteClick"
        v-if="isAdmin && uploadMode"
        class="icon-cross"
      ></span>
      <span class="icon-check-circle"></span>
      <span v-if="image.username" class="username">{{ image.username }}</span>
    </div>
  </li>
</template>

<script>
export default {
  name: "PictureListElement",
  props: {
    image: Object,
    selected: Boolean,
    clickCallback: Function,
    deleteClickCallback: Function,
  },
  computed: {
    uploadMode() {
      return this.$root.mode == "upload";
    },
    isAdmin() {
      return !!this.$root.jwtToken;
    },
  },
  methods: {
    onClick(e) {
      if (this.uploadMode) {
        return;
      }
      if (typeof this.clickCallback === "function") {
        this.clickCallback(e, this.image);
      }
    },
    onDeleteClick(e) {
      if (!this.uploadMode) {
        return;
      }
      if (!this.isAdmin) {
        return;
      }
      if (typeof this.deleteClickCallback === "function") {
        this.deleteClickCallback(e, this.image);
      }
    },
    onClickWithShift(e) {
      if (this.uploadMode) {
        return;
      }
      if (typeof this.clickCallback === "function") {
        this.clickCallback(e, this.image, true); // true for shift key
      }
    },
  },
};
</script>
