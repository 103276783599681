<template>
  <div v-if="visible" class="alert-container">
    <div class="alert">
      <h4>{{ $t("what_s_your_name") }}</h4>
      <p>{{ $t("explaination_why_name_is_important") }}</p>
      <div class="input-container">
        <label for="input">{{ $t("name") }}</label>
        <div key="add" class="input input-with-button">
          <input
            ref="usernameInput"
            name="input"
            id="input"
            v-model="username"
            type="text"
          />
          <custom-button :button="buttonClear"></custom-button>
        </div>
      </div>
      <div class="buttons">
        <custom-button :button="buttonCancel"></custom-button>
        <custom-button :button="buttonOK"></custom-button>
      </div>
    </div>
  </div>
</template>

<script>
import CustomButton from "./CustomButton.vue";

export default {
  name: "UsernameAlert",
  components: { CustomButton },
  props: {
    callback: Function,
  },
  data() {
    return {
      username: "",
      visible: false,
    };
  },
  created() {
    this.username = localStorage.getItem("username") ?? "";
  },
  methods: {
    onCancelClicked() {
      this.visible = false;
      if (typeof this.callback === "function") {
        this.callback(null);
      }
    },
    onOKClicked() {
      this.visible = false;
      localStorage.setItem("username", this.username);
      if (typeof this.callback === "function") {
        this.callback(this.username);
      }
    },
    onClearClicked() {
      this.username = "";
      localStorage.removeItem("username");
    },
  },
  computed: {
    buttonOK() {
      return {
        text: this.$i18n.t("continue"),
        disabled: this.username ? false : true,
        clickCallback: this.onOKClicked,
      };
    },
    buttonCancel() {
      return {
        customClass: "secondary-button",
        text: this.$i18n.t("skip"),
        clickCallback: this.onCancelClicked,
      };
    },
    buttonClear() {
      return {
        icon: "icon-cross",
        disabled: this.username ? false : true,
        clickCallback: this.onClearClicked,
      };
    },
  },
};
</script>

<style lang="less" scoped>
@import "../styles/form.less";

.alert-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 20px;
  padding-left: calc(env(safe-area-inset-left) + 20px);
  padding-right: calc(env(safe-area-inset-right) + 20px);
  padding-top: calc(env(safe-area-inset-top) + 20px);
  padding-bottom: calc(env(safe-area-inset-bottom) + 20px);
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--bkg-overlay-color);
}

.alert {
  background-color: var(--bkg-alert-color);
  padding: 20px;
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius);
  max-width: calc(var(--max-width) / 1.5);

  h4 {
    margin-bottom: 20px;
    font-size: 2em;
    line-height: 1em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  p {
    font-size: 1.2em;
    line-height: 1.2em;
    color: var(--text-light-color);
  }

  .buttons {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;

    .button {
      flex: 1;
      margin: 0 10px;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.switch-to-select-enter-active,
.switch-to-select-leave-active,
.switch-to-input-enter-active,
.switch-to-input-leave-active {
  transition-duration: 0.3s;
  transition-property: transform;
  transition-timing-function: ease-in-out;
  overflow: hidden;
}

.switch-to-select-enter,
.switch-to-input-leave-active {
  transform: translate(100%, 0);
}

.switch-to-select-leave-active,
.switch-to-input-enter {
  transform: translate(-100%, 0);
}
</style>
