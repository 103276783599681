<template>
  <div class="select">
    <select @input="$emit('input', $event.target.value)">
      <option
        v-for="option in options"
        :key="option.value"
        :value="option.value"
        :selected="option.value == selectedValue"
      >
        {{ option.text }}
      </option>
    </select>
    <span class="icon-keyboar-down select-down-icon"></span>
  </div>
</template>

<script>
export default {
  name: "CustomSelect",
  props: {
    options: Array,
    selectedValue: String,
  },
};
</script>
