<template>
  <div class="sub-menu" :style="{ '--sub-menu-shadow-opacity': shadowOpacity }">
    <div class="sub-menu-content">
      <span class="separator"></span>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "SubMenu",
  components: {},
  props: {},
  data() {
    return {
      shadowOpacity: 0,
    };
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      const currentScroll = window.top.scrollY;
      let opacity = currentScroll / 100;
      if (opacity < 0) {
        opacity = 0;
      }
      if (opacity > 1) {
        opacity = 1;
      }
      this.shadowOpacity = opacity;
    },
  },
};
</script>

<style lang="less" scoped>
.sub-menu {
  --sub-menu-shadow-opacity: 0;

  position: fixed;
  top: calc(2.8em + 2 * 10px + 1px);
  top: calc(2.8em + 2 * 10px + 1px + env(safe-area-inset-top));
  left: 0;
  width: 100%;
  z-index: 10;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: var(--sub-menu-shadow-opacity);
    z-index: -1;
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.3) 100%
    );
  }

  .sub-menu-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    padding: 0 10px;
    padding-left: calc(env(safe-area-inset-left) + 10px);
    padding-right: calc(env(safe-area-inset-right) + 10px);
    box-sizing: border-box;
    min-height: calc(2.8em + 2 * 10px + 1px);

    @media screen and (min-width: 1200px) {
      width: var(--max-width2);
    }

    .separator {
      flex: 1;
    }

    & > * {
      margin: 0 10px;
    }

    & > :first-child {
      margin-left: 0;
    }

    & > :last-child {
      margin-right: 0;
    }

    .button-container {
      margin: 10px 0;
      background-color: var(--bkg-color);
      border-radius: var(--border-radius);
      position: relative;

      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: var(--border-radius);
        opacity: var(--sub-menu-shadow-opacity);
        z-index: -1;
        box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
      }
    }

    .button-container + .button-container {
      margin-left: 10px;
    }

    .button {
      display: block;
    }

    label + .select {
      margin-left: 0;
    }

    .text {
      position: relative;

      &::after {
        content: attr(data-text);
        position: absolute;
        color: white;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: var(--sub-menu-shadow-opacity);
        text-shadow: black 0 2px 10px;
      }
    }
  }
}
</style>
